import {
    DataLayerEventName,
    ImageSource,
    PagePosition,
    PlayerEvent,
    PlaylistType,
    Standard,
    VideoConfig,
    VideoDetails,
    VideoQueueItemMeta,
} from '@news-mono/web-common'
import { VideoMetaDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { Logger } from 'typescript-log'
import countdownTimer from '../../content/Video/components/TitleOverlay/countdown-timer'
import {
    brightcovePlayerWebId,
    PlayerProps as SwmPlayerProviderProps,
    RuntimeVideoConfig,
} from '../../content/Video/providers/PlayerProps'
import {
    AutoplayAllowState,
    videoCanAutoplay,
} from '../../content/Video/utils/can-autoplay'
import {
    getMetadata,
    parseSourceFromMetadata,
} from '../../content/Video/utils/get-metadata'
import { VideoQueue } from '../../templates/Publication/lib/get-video-queue'
import { ViewportProps } from '../../__helpers/in-viewport'
import { ThemeProps } from '../../__styling/themes'
import {
    AutoplayCountdown,
    Other,
    RenderPlayerOptions,
    RenderPlayerParams,
} from './player-render-params'
import { swmPlayerDebug } from './providers/debug-log'

export type GetVideoQueue = () => Promise<VideoQueue>
export type PlayerProvider = React.ComponentType<SwmPlayerProviderProps>

export interface AutoplayOptions {
    delayDuration: number
    pauseCountdownOutsideViewport?: boolean
    pauseWhenOutOfView?: boolean //pauses when out of view, plays when in view
}

export interface VideoOptions extends RenderPlayerOptions {
    adRequestMode?: BrightcovePlayer.AdRequestMode
    preloadOption?: 'auto' | 'metadata' | 'none'
    adUnitPath: string

    // Feature toggles
    enableNielsenVideoReporting: boolean
    enableNielsenSubbrandReporting: boolean
    enableSnowplow: boolean
    enablePrebid: boolean
    canAutoplayVideos: boolean
    gdprMode: boolean
    isMagniteEnabled?: boolean
}

export interface PlayerInterfaceProps extends ThemeProps, ViewportProps {
    videoDetails: VideoDetails
    videoOptions: VideoOptions
    // todo do something with this
    pauseOverlay?: boolean
    // shows the video title bar
    showTitles: boolean
    /** false to disable autoplay */
    autoplayOptions: AutoplayOptions | false
    autoplayNextOptions: AutoplayOptions | false
    onEvent: (event: PlayerEvent) => void
    getVideoQueue: GetVideoQueue
    pagePosition: PagePosition
    /**
     * The `playWhenReady` prop tells the video to try and play the video. You can use this prop to try autoplay the
     * video immediately, but its preferable to use the autoplay options for that instead. This prop should only be used
     * if you need to effect the playing of the video component from its the parent component.
     */
    playWhenReady?: boolean
    onUserInteraction: (e: PlayerEvent) => void
    logger: Logger
    config: VideoConfig & RuntimeVideoConfig
    renderPlayer: (
        renderPlayerParams: RenderPlayerParams,
    ) => React.ReactElement<any>
    playerProvider: () => PlayerProvider
    showPlaylist?: boolean
    pathname: string
    isVideoHub?: boolean
    isVideoHubPlayListFixEnabled?: boolean
    onPlayNextVideo?: (nextVideo: VideoQueueItemMeta) => void
}

interface State {
    adPlayed: boolean
    adPlaying: boolean
    autoplayHasCompleted: boolean
    isFullscreen: boolean
    currentVideo: VideoDetails
    currentVideoMetadata: VideoMetaDTO | undefined
    initialVideoMetadata: VideoMetaDTO | undefined
    currentVideoPlaylistType: PlaylistType
    showTopBar: boolean
    playWhenReady: boolean
    videoPlayable: boolean
    showInternalPlayButton: boolean
    isMuted: boolean
    videoQueue: VideoQueue
    queuePosition: number
    canAutoplay: boolean
    pauseVideo: boolean

    // Will just trial using playerState for counting down
    playerState: Other | AutoplayCountdown
}

export class PlayerInterface extends React.Component<
    PlayerInterfaceProps,
    State
> {
    mounted = false
    playerOffsetWidth = 0
    autoplayInterval?: number

    constructor(props: PlayerInterfaceProps) {
        super(props)

        this.state = {
            adPlayed: false,
            adPlaying: false,
            autoplayHasCompleted: false,
            isFullscreen: false,
            currentVideo: this.props.videoDetails,
            currentVideoMetadata: undefined,
            initialVideoMetadata: undefined,
            currentVideoPlaylistType: 'not-playlist-video',
            showTopBar: false,
            playWhenReady: props.playWhenReady || false,
            videoPlayable: false,
            showInternalPlayButton: true,
            isMuted: true,
            canAutoplay: false,
            videoQueue: {
                type: 'not-playlist-video',
                items: [],
            },
            // video hub needs a one-based index as initial video is part of queue playlist
            queuePosition: props.isVideoHub ? 1 : 0,
            playerState: { type: 'other' },
            //start in paused state if using PauseWhenOutOfView option
            pauseVideo:
                props.autoplayOptions &&
                props.autoplayOptions.pauseWhenOutOfView === true
                    ? true
                    : false,
        }
    }

    async componentDidMount() {
        try {
            this.mounted = true
            const metadata = await getMetadata(
                this.state.currentVideo.id,
                this.state.currentVideo.accountId,
                this.props.config,
                this.props.logger,
            )
            if (!this.mounted) {
                return
            }

            const autoplayMuteState = await videoCanAutoplay({
                forceEnableAutoplay: this.props.isVideoHub,
            })

            if (!this.mounted) {
                return
            }
            const canAutoplay =
                autoplayMuteState !== AutoplayAllowState.CannotAutoplay
            const shouldAutoplay =
                this.props.autoplayOptions !== false && canAutoplay

            // For some reason just checking shouldAutoplay is not enough
            if (
                shouldAutoplay &&
                this.props.autoplayOptions !== false &&
                this.state.playWhenReady === false
            ) {
                this.initAutoplay(this.props.autoplayOptions)
            }

            const currentVideo = {
                ...this.state.currentVideo,
                source: parseSourceFromMetadata(
                    metadata.source,
                    metadata.sourceExtra,
                ),
            }

            this.setState({
                currentVideo,
                currentVideoMetadata: metadata,
                initialVideoMetadata: metadata,
                isMuted: true,
                canAutoplay,
                showTopBar: false,
                showInternalPlayButton:
                    (!shouldAutoplay && !this.state.playWhenReady) || false,
            })
        } catch (err) {
            this.props.logger.error(
                { err: err as Error },
                'metadata get failed',
            )
        }
    }

    isPlaylistDifferent(
        prev: VideoQueueItemMeta[],
        curr: VideoQueueItemMeta[],
    ) {
        if (
            JSON.stringify([...curr].sort((a: any, b: any) => a.id - b.id)) !==
            JSON.stringify([...prev].sort((a: any, b: any) => a.id - b.id))
        ) {
            return true
        } else {
            return false
        }
    }

    async componentDidUpdate(prevProps: PlayerInterfaceProps) {
        if (prevProps.videoDetails?.id !== this.props.videoDetails.id) {
            const metadata = await getMetadata(
                this.props.videoDetails.id,
                this.props.videoDetails.accountId,
                this.props.config,
                this.props.logger,
            )

            const currentVideo = {
                ...this.props.videoDetails,
                source: parseSourceFromMetadata(
                    metadata.source,
                    metadata.sourceExtra,
                ),
            }

            this.setState({
                currentVideo,
                currentVideoMetadata: metadata,
                initialVideoMetadata: metadata,
            })
        }

        if (this.props.isVideoHubPlayListFixEnabled && this.props.isVideoHub) {
            const videoQueue = await this.props.getVideoQueue()
            if (
                this.isPlaylistDifferent(
                    this.state.videoQueue.items,
                    videoQueue.items,
                )
            ) {
                this.setState({
                    videoQueue,
                })
            }
        }

        if (
            !prevProps.playWhenReady &&
            this.props.playWhenReady &&
            !this.state.playWhenReady
        ) {
            this.playVideo()
        }

        if (
            this.props.autoplayOptions &&
            this.props.autoplayOptions.pauseWhenOutOfView
        ) {
            if (
                (prevProps.inViewport >= 50 && this.props.inViewport < 50) ||
                (document.hidden && !this.state.pauseVideo)
            ) {
                this.pauseVideo()
            }

            if (prevProps.inViewport < 50 && this.props.inViewport >= 50) {
                this.resumeVideo()
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false
        if (this.autoplayInterval !== undefined) {
            countdownTimer.clearInterval(this.autoplayInterval)
        }
    }

    initAutoplay = (autoplayOptions: AutoplayOptions) => {
        if (!this.mounted) {
            return
        }
        this.setState(
            {
                playerState: {
                    type: 'autoplay-countdown',
                    autoplayOptions,
                    remainingTime:
                        autoplayOptions && autoplayOptions.delayDuration
                            ? autoplayOptions.delayDuration
                            : 0,
                },
            },
            () => {
                this.autoplayInterval = countdownTimer.setInterval(
                    this.updateAutoplayTime,
                    1000,
                )
            },
        )
    }

    updateAutoplayTime = () => {
        if (this.state.playerState.type !== 'autoplay-countdown') {
            this.props.logger.error(
                {
                    playerState: this.state.playerState,
                },
                'Received updateAutoplayTime event when not in autoplay-countdown state',
            )
            return
        }

        // magic number "50" represents "50%" of the viewport height
        const isInViewport = this.props.inViewport > 50

        if (
            this.state.playerState.autoplayOptions
                .pauseCountdownOutsideViewport &&
            (isInViewport === false || document.hidden)
        ) {
            return
        }

        if (this.state.playerState.remainingTime > 0) {
            const remainingTime = this.state.playerState.remainingTime - 1
            return this.setState((state) => ({
                playerState: {
                    ...state.playerState,
                    remainingTime,
                },
            }))
        }

        this.autoplayComplete()
    }

    autoplayComplete = () => {
        if (this.autoplayInterval !== undefined) {
            countdownTimer.clearInterval(this.autoplayInterval)
        }
        this.setState({
            playerState: { type: 'other' },
            showTopBar: false,
            playWhenReady: true,
            autoplayHasCompleted: true,
            showInternalPlayButton: false,
        })
    }

    /** Cancels autoplay timer, call when user initiates video play when counting down */
    cancelAutoplay = () => {
        if (this.autoplayInterval !== undefined) {
            countdownTimer.clearInterval(this.autoplayInterval)
        }
        this.setState({
            playerState: { type: 'other' },
        })
    }

    /** User initiated stop of autoplay countdown */
    stopAutoplay = () => {
        this.cancelAutoplay()
        this.setState({
            showTopBar: false,
            autoplayHasCompleted: false,
            showInternalPlayButton: true,
        })
    }

    closeStickyVideo = () => {
        this.setState({ pauseVideo: true })
    }

    playerReady = async () => {
        if (!this.props.autoplayNextOptions) {
            return
        }

        try {
            // TODO state.videoPlayable is not true until this is done
            // could we be lazy here?
            const videoQueue = await this.props.getVideoQueue()
            this.setState({
                videoQueue,
            })
        } catch (err) {
            this.props.logger.error(
                { err: err as Error },
                `getVideoQueue() failed!`,
            )
        }
    }

    videoCompleted = async () => {
        const nextVideo = this.state.videoQueue.items[this.state.queuePosition]

        if (!nextVideo) {
            return
        }

        this.playNextVideo(nextVideo)

        swmPlayerDebug(`Starting autoplay countdown for: ${nextVideo.id}`)

        if (this.props.autoplayNextOptions !== false) {
            this.initAutoplay(this.props.autoplayNextOptions)
        }
    }

    playNextVideo = (
        nextVideo: VideoQueueItemMeta,
        playImmediately = false,
    ) => {
        if (!this.mounted) {
            return
        }

        this.props.onPlayNextVideo && this.props.onPlayNextVideo(nextVideo)

        const currentVideo: VideoDetails = {
            id: nextVideo.id,
            accountId: nextVideo.accountId,
            source: parseSourceFromMetadata(
                nextVideo.source,
                nextVideo.sourceExtra,
            ),
            heading: nextVideo.name || nextVideo.description,
            videoType: nextVideo.videoType as VideoDetails['videoType'],
        }

        // add the poster image if it exists in the nextVideo
        if (
            nextVideo.posterImage &&
            nextVideo.posterImage.reference.indexOf('https') >= 0
        ) {
            const poster: ImageSource = {
                altText:
                    nextVideo.posterImage.altText ||
                    nextVideo.posterImage.captionText,
                crops:
                    typeof nextVideo.posterImage.ratio === 'string'
                        ? {
                              [nextVideo.posterImage.ratio]: {
                                  reference: nextVideo.posterImage.src,
                                  width: nextVideo.posterImage.width,
                                  height: nextVideo.posterImage.height,
                              },
                          }
                        : {
                              original: {
                                  reference: nextVideo.posterImage.src,
                                  width: nextVideo.posterImage.width,
                                  height: nextVideo.posterImage.height,
                                  ratio: nextVideo.posterImage.ratio,
                              },
                          },
            }
            currentVideo.posterImage = poster
        }

        const queuePosition = this.state.videoQueue.items.findIndex(
            (item) => item.id === nextVideo.id,
        )

        this.setState(
            {
                autoplayHasCompleted: false,
                currentVideo,
                currentVideoMetadata: nextVideo,
                currentVideoPlaylistType: this.state.videoQueue.type,
                playWhenReady: false,
                showInternalPlayButton: this.props.isVideoHub ? false : true,
                showTopBar: false,
            },
            () => {
                // Increment queuePosition after we have applied the state change
                // otherwise our analytics may report the wrong position
                this.setState({
                    queuePosition: queuePosition + 1,
                    playWhenReady: playImmediately,
                })

                if (playImmediately) {
                    this.cancelAutoplay()
                }
            },
        )
    }

    variant = (): Standard['videoPlayerVariant'] => {
        return this.props.videoOptions.canAutoplayVideos
            ? 'Autoplay'
            : 'Default-BigPlayButton'
    }

    handleMuteToggle = () => {
        this.setState({ isMuted: !this.state.isMuted })
    }

    onPlayerEvent = async (event: PlayerEvent) => {
        swmPlayerDebug('Video event raised', event)

        switch (event.type) {
            case DataLayerEventName.playToPauseError:
                // ad is skipped in this condition (fails)
                // main video logs that it is playing in the background, but is not displayed
                this.setState({
                    showInternalPlayButton: this.props.isVideoHub
                        ? false
                        : true,
                    playWhenReady: false,
                    adPlaying: false,
                    adPlayed: true,
                })
                break
            case DataLayerEventName.playbackError:
                this.setState({
                    videoPlayable: true,
                })
                break
            case DataLayerEventName.playerReady:
                await this.playerReady()
                this.setState({
                    videoPlayable: true,
                })
                break
            case DataLayerEventName.videoAvailable:
                if (this.state.playWhenReady) {
                    this.playVideo()
                }
                break
            case DataLayerEventName.videoPlay:
                this.cancelAutoplay()
                this.setState({
                    showInternalPlayButton: false,
                    playWhenReady: false,
                })
                this.props.onUserInteraction(event)
                return
            case DataLayerEventName.videoStart:
                this.setState({
                    showInternalPlayButton: false,
                    adPlayed: true,
                })
                break
            case DataLayerEventName.videoEnd:
                this.videoCompleted()
                break
            case DataLayerEventName.adError:
                break
            case DataLayerEventName.adStart:
                this.cancelAutoplay()
                this.setState({
                    showInternalPlayButton: false,
                    adPlayed: false,
                    adPlaying: true,
                })
                break
            case DataLayerEventName.adEnd:
                this.setState({
                    adPlayed: true,
                    adPlaying: false,
                })
                break
            case DataLayerEventName.adPodComplete:
                this.setState({
                    adPlayed: true,
                    adPlaying: false,
                })
                break
            case DataLayerEventName.playerFullscreenEnter:
                this.setState({ isFullscreen: true })
                break
            case DataLayerEventName.playerFullscreenExit:
                this.setState({ isFullscreen: false })
                break
            case DataLayerEventName.playerUserInactive:
                this.setState({ showTopBar: false })
                break
            case DataLayerEventName.videoVolumeChange:
                if (!event.payload.muted) {
                    this.setState({ isMuted: false })
                }
                break
            case DataLayerEventName.adVolumeChange:
                if (!event.payload.muted) {
                    this.setState({ isMuted: false })
                }
                break
        }

        this.props.onEvent(event)
    }

    handleMouseEnter = (_event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({ showTopBar: true })
    }

    handleMouseMove = (_event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.state.showTopBar) {
            this.setState({ showTopBar: true })
        }
    }

    handleMouseLeave = (_event: React.MouseEvent<HTMLDivElement>) => {
        this.setState({ showTopBar: false })
    }

    handleTouchStart = (_event: React.TouchEvent<HTMLDivElement>) => {
        this.setState({ showTopBar: true })
    }

    handleTouchEnd = (_event: React.TouchEvent<HTMLDivElement>) => {
        // do we want to wait a few seconds before dissapearing here
        // previous video player simply never hid the banner after a touch start

        this.setState({ showTopBar: false })
    }

    handlePlaylistSelect = (video: VideoQueueItemMeta) => {
        this.playNextVideo(video, true)
        this.props.onUserInteraction({
            type: DataLayerEventName.playlistSelect,
            originator: 'playlist-event',
            payload: {
                video,
            },
        })
    }

    handlePlaylistScroll = () => {
        this.props.onUserInteraction({
            type: DataLayerEventName.playlistScroll,
            originator: 'playlist-event',
            payload: {},
        })
    }

    setRef = (playerVideo: HTMLElement | null) => {
        if (playerVideo) {
            this.playerOffsetWidth = playerVideo.offsetWidth
        }
        this.props.innerRef(playerVideo)
    }

    playVideo = () => {
        // The video provider component waits for a prop change to trigger a play,
        // this callback ensures the prop will change from false->true preventing edge case state issues
        this.setState(
            {
                playWhenReady: false,
            },
            () => {
                this.setState({
                    playWhenReady: true,
                    showInternalPlayButton: false,
                    isMuted: true,
                })
            },
        )
    }

    pauseVideo = () => {
        this.setState({
            pauseVideo: true,
        })
    }

    resumeVideo = () => {
        this.setState(() => {
            this.setState({
                pauseVideo: false,
                playWhenReady: true,
            })
        })
    }

    render() {
        const { videoOptions } = this.props
        const PlayerProviderComponent = this.props.playerProvider()

        return this.props.renderPlayer({
            theme: this.props.theme,
            playerActions: {
                handleMuteToggle: this.handleMuteToggle,
                playVideo: this.playVideo,
                stopAutoplay: this.stopAutoplay,
                closeStickyVideo: this.closeStickyVideo,
                pauseVideo: this.pauseVideo,
                resumeVideo: this.resumeVideo,
            },
            playerOptions: videoOptions,
            playerState: {
                isMuted: this.state.isMuted,
                adPlayed: this.state.adPlayed,
                adPlaying: this.state.adPlaying,
                canAutoplay: this.state.canAutoplay,
                currentVideo: this.state.currentVideo,
                currentVideoMetadata: this.state.currentVideoMetadata,
                currentVideoPlaylistType: this.state.currentVideoPlaylistType,
                isFullscreen: this.state.isFullscreen,
                showInternalPlayButton: this.state.showInternalPlayButton,
                showTitleOverlay:
                    this.state.playerState.type === 'autoplay-countdown',
                showTopBar: this.state.showTopBar,
                inViewport: this.props.inViewport,
                currentState: this.state.playerState,
            },
            wrapperElProps: {
                innerRef: this.setRef,
                onMouseEnter: this.handleMouseEnter,
                onMouseLeave: this.handleMouseLeave,
                onMouseMove: this.handleMouseMove,
                onTouchEnd: this.handleTouchEnd,
                onTouchStart: this.handleTouchStart,
            },
            playlistProps: this.props.showPlaylist
                ? {
                      currentVideoId: this.state.currentVideo.id,
                      videoQueue: this.state.videoQueue,
                      initialVideoMetadata: this.state.initialVideoMetadata,
                      handlePlaylistScroll: this.handlePlaylistScroll,
                      handlePlaylistSelect: this.handlePlaylistSelect,
                  }
                : undefined,
            // Need the surrounding div to ensure the videoJS dom manipulations
            // do not cause errors
            videoElement: this.state.currentVideoMetadata ? (
                <div>
                    <PlayerProviderComponent
                        logger={this.props.logger}
                        config={this.props.config}
                        playerOptions={{
                            ...videoOptions,
                            brightcovePlayerId: brightcovePlayerWebId,
                        }}
                        isMuted={this.state.isMuted}
                        adPlaying={this.state.adPlaying}
                        autoplayOptions={this.props.autoplayOptions}
                        autoplayHasCompleted={this.state.autoplayHasCompleted}
                        play={
                            this.state.videoPlayable && this.state.playWhenReady
                        }
                        pause={this.state.pauseVideo}
                        currentVideo={this.state.currentVideo}
                        currentVideoMetadata={this.state.currentVideoMetadata}
                        currentVideoPosition={this.state.queuePosition}
                        playlistType={this.state.currentVideoPlaylistType}
                        isFullscreen={this.state.isFullscreen}
                        onEvent={this.onPlayerEvent}
                        pagePosition={this.props.pagePosition}
                        variant={this.variant()}
                        product={this.props.theme.kind}
                        pathname={this.props.pathname}
                        isVideoHub={this.props.isVideoHub}
                    />
                </div>
            ) : null,
        })
    }
}
